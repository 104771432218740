<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    style="z-index:200000001;"
  >
    <v-card >
      <v-card-title class="flex-row justify-between d-flex app-bold-font">
        Edit Competition
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <div style="min-height: 500px; max-height: 61vh; overflow: auto">
        <v-card-text class="app-regular-font">
          Edit the competitions and invite by agencies.
        </v-card-text>
        <v-card-text class="justify-center py-0 d-flex flex-column">
          <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
            Title
          </div>
          <v-text-field
            label="title"
            solo
            flat
            outlined
            class="mt-2 app-regular-font"
            v-model="competition.title"
            :rules="[rules.required]"
            dense
          />
        </v-card-text>
        <v-card-text class="justify-center py-0 d-flex flex-column">
          <div class="app-medium-font dark-font-color">
            Description
          </div>
          <v-textarea
            rows="4"
            auto-grow
            label="Description"
            solo
            flat
            outlined
            class="mt-2 app-regular-font"
            v-model="competition.description"
            :rules="[rules.required]"
          />
        </v-card-text>
        <v-card-text
          class="py-0 d-flex"
          :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
        >
          <div :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-3'">
            <div class="mt-2 d-flex" :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'">
              <div :style="$vuetify.breakpoint.xs ? 'width: 100%' : 'width: 200px;'">
                <v-menu
                  v-model="start_date_picker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formattedStartDate"
                      label="Start Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="start_date" @input="saveStartDate"></v-date-picker>
                </v-menu>
              </div>
              <div :style="$vuetify.breakpoint.xs ? 'width: 100%' : 'width: 200px;'">
                <v-menu
                  v-model="end_date_picker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formattedEndDate"
                      label="End Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="end_date" @input="saveEndDate"></v-date-picker>
                </v-menu>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-text
          class="py-0 d-flex"
          :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
        >
          <div :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-3'">
            <div class="app-medium-font dark-font-color">Agencies</div>
            <div
              class="d-flex"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <div
                :style="
                  $vuetify.breakpoint.xs ? 'width: 100%' : 'width: 550px;'
                "
              >
                <v-select
                  :items="agencyList"
                  item-text="name"
                  item-value="_id"
                  label="Select Agency"
                  solo
                  flat
                  outlined
                  dense
                  class="mt-2 app-regular-font"
                  v-model="competition.requested_agency_ids"
                  :rules="[rules.required]"
                  multiple
                  @change="updateAllSelected"
                  disabled
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="selectAllAgencies">
                      <v-list-item-action>
                        <v-checkbox :input-value="allSelected" color="primary"></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-text
          class="py-0 d-flex"
          :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
        >
          <div>
            <div style="font-family:'Poppins-Medium'; color: #1e1e1e" class="ms-3">
              Target Points
            </div>
            <div
              :style="$vuetify.breakpoint.xs ? 'width: 100%' : 'width: 200px;'"
            >
              <v-text-field
                label="Ex:120"
                solo
                flat
                outlined
                dense
                class="mt-2 ms-3 app-regular-font"
                v-model="competition.target_points"
                type="number"
                :rules="[rules.required]"
              />
            </div>
          </div>
        </v-card-text>
        <v-card-text v-if="message">
          <v-alert dense type="error" v-text="message" v-if="message" />
        </v-card-text>
      </div>
      <v-card-actions class="flex-row justify-center pb-6 mt-3 d-flex">
        <v-btn
          color="#b5e539"
          @click="onSave"
          class="white--text"
          width="150"
          :loading="loading"
          >Update</v-btn>
        <v-btn color="red" class="white--text" width="150" @click="onClose">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function
    },
    respectiveCompetition: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      message: null,
      loading: false,
      rules: {
        required: value => !!value || "Required."
      },
      competition: { ...this.respectiveCompetition },
      agency: {},
      agencyList: [],
      start_date_picker: false,
      end_date_picker: false,
      awardDate: null,
      start_date: this.respectiveCompetition.start_date
        ? this.respectiveCompetition.start_date.split("T")[0]
        : "", // Initialize start_date as string
      end_date: this.respectiveCompetition.end_date
        ? this.respectiveCompetition.end_date.split("T")[0]
        : "", // Initialize end_date as string
      newName: null,
      newUnit: null,
      list: [],
      allSelected: false
    };
  },
  computed: {
    formattedStartDate() {
      return this.start_date; // Use start_date
    },
    formattedEndDate() {
      return this.end_date; // Use end_date
    },
    ...mapState("auth", {
      profile: "profile"
    })
  },
  watch: {
    dialog(val) {
      this.internalDialog = val;
      if (val) {
        this.resetForm();
      }
    },
    internalDialog(val) {
      this.$emit("update:dialog", val);
    },
    respectiveCompetition: {
      handler(newVal) {
        this.competition = { ...newVal };
        this.start_date = newVal.start_date
          ? newVal.start_date.split("T")[0]
          : ""; // Update start_date as string
        this.end_date = newVal.end_date
          ? newVal.end_date.split("T")[0]
          : ""; // Update end_date as string
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      updateCompetition: "competition/editCompetition",
      getCompetition: "competition/getCompetition"
    }),
    selectAllAgencies() {
      if (this.allSelected) {
        this.competition.requested_agency_ids = [];
      } else {
        this.competition.requested_agency_ids = this.agencyList.map(
          item => item._id
        );
      }
      this.allSelected = !this.allSelected;
    },
    updateAllSelected() {
      this.allSelected =
        this.competition.requested_agency_ids.length === this.agencyList.length;
    },
    saveStartDate(date) {
      this.start_date = date; // Update start_date as string
      this.competition.start_date = date;
      this.start_date_picker = false;
    },
    saveEndDate(date) {
      this.end_date = date; // Update end_date as string
      this.competition.end_date = date;
      this.end_date_picker = false;
    },
    onSave() {
      if (!this.competition.title || this.competition.title === "") {
        this.message = "Please add title.";
        return;
      }
      if (!this.competition.description) {
        this.message = "Please add description.";
        return;
      }
      if (!this.start_date) {
        this.message = "Please add Start Date.";
        return;
      }
      if (!this.end_date) {
        this.message = "Please add End Date.";
        return;
      }
      if (!this.competition.target_points) {
        this.message = "Please add Target Points.";
        return;
      }
      if (
        !this.competition.requested_agency_ids ||
        this.competition.requested_agency_ids.length === 0
      ) {
        this.message = "Please add Agency.";
        return;
      }
      this.competition.agency_id = this.agency._id;
      var params = {
        competitionId: this.respectiveCompetition._id,
        ...this.competition,
        start_date: this.start_date, // Use start_date
        end_date: this.end_date, // Use end_date
        requested_agency_ids: this.competition.requested_agency_ids,
        agency_id: this.agency._id,
        status: this.competition.status || "Active",
        competition_status: this.competition.competition_status || "Ongoing"
      };

      this.loading = true;
      this.updateCompetition(params)
        .then(() => {
          this.loading = false;
          this.reset();
          this.onClose();
        })
        .catch(error => {
          this.loading = false;
          this.message = error;
        });
    },
    reset() {
      this.competition = { ...this.respectiveCompetition };
      this.start_date = this.respectiveCompetition.start_date
        ? this.respectiveCompetition.start_date.split("T")[0]
        : ""; // Reset start_date
      this.end_date = this.respectiveCompetition.end_date
        ? this.respectiveCompetition.end_date.split("T")[0]
        : ""; // Reset end_date
      this.message = null;
      this.allSelected = false;
    },
    close() {
      this.dialog = false;
    },
    changeDateFormat(dateStr) {
      if (!dateStr) return null;
      console.log(dateStr.substring(5, 7), "dateStr");
      return `${dateStr.substring(5, 7)}/${dateStr.substring(
        8,
        10
      )}/${dateStr.substring(0, 4)}`;
    },
    loadagencyList() {
      const agencyId = this.agency._id;
      this.getCompetition({ agencyId })
        .then(res => {
          this.agencyList = res;
          this.allSelected = false;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.message = error.response.data.message;
        });
    }
  },
  mounted() {
    this.agency = this.profile;
    this.loadagencyList();
  }
};
</script>

<style scoped>
@media (max-width: 600px) {
  div :deep(.v-dialog) {
    margin: 0;
    /* padding-top: 17px; */
    /* padding-bottom: 17px; */
    /* height: 100%; */
  }
}
@media (min-width: 600px) {
  .v-dialog__content {
    z-index: 2000001 !important;
  }
  div :deep(.v-dialog) {
    /* height: 80vh; */
    width: 70%;
    max-width: 900px;
    overflow-y: hidden;
  }
}
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}

.time-picker {
  width: 107px;
  border-color: #d094ea !important;
  border-width: 1px;
  height: 40px;
}
.time-picker :deep(input.display-time) {
  height: 40px !important;
  width: 100%;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.38);
  padding: 0 15px;
}
.time-picker :deep(input.display-time:hover) {
  border-color: currentColor;
}
.time-picker :deep(input.display-time:focus-visible) {
  border-color: #d094ea !important;
  border-width: 1px;
}
.time-picker :deep(.clear-btn) {
  padding: 5px 16px;
  margin-top: 5px;
}
</style>